/*
    Created on : Nov 8, 2022, 10:40:43 AM
    Author     : Nikolay
*/

.side-menu {

    background-color: #69b2c0;
    height: 100%;
    margin-top: 60px;
    color: #fff;
    font-weight: 600;
    box-sizing: border-box;
    position:fixed;
    width: 240px;
    left: -240px;
    transition: left 0.5s;
    z-index: 10;

    nav {
        position: relative;
        height: 100%;
    }

    ul, li {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    a {
        color: #fff;
        font-weight: 600;
        text-decoration: none;
        font-size: 18px;
        display: block;
        padding: 15px 16px;

        &:hover, &:active {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    &.active {
        left: 0;
    }

    .version {
        position: absolute;
        bottom: 70px;
        left: 20px;
    }

}