.audio-bar {
    
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
    
    .circle {
        background-color: #fff;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        top: -4px;
        position: absolute;
    }
    
    .line {
        position: relative;
        width: 100px;
        background-color: rgba(0, 0, 0, 0.3);
        height: 4px;
        
        .line-content {
            background-color: rgba(255, 255, 255, 1);
            height: 4px;
        }
    }
    
    .time, .duration {
        width: 30px;
    }
    
}