/*
    Created on : Nov 8, 2022, 9:53:07 AM
    Author     : Nikolay
*/
.body {
    header {

        transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        /*background-color: #69b2c0;*/
        background: linear-gradient(180deg, rgba(2, 0, 36, 0.8) -15%, rgb(129 208 225) 25%, rgb(83 157 173) 50%, rgb(49 137 155) 75%, rgb(105, 178, 192) 100%);
        top: 0;
        left: auto;
        right: 0;
        position: fixed;
        height: 60px;
        border: 1px solid rgba(105,178,192,1);
        border: 0.1px ridge rgb(105, 178, 192, 0.7);

        .button {
            margin-right: 28px;
            margin-left: 0px;
            color: inherit;
            border: 0;
            cursor: pointer;
            display: inline-flex;
            outline: 0;
            padding: 0;
            position: relative;
            align-items: center;
            user-select: none;
            border-radius: 0;
            vertical-align: middle;
            -moz-appearance: none;
            justify-content: center;
            text-decoration: none;
            background-color: transparent;
            -webkit-appearance: none;
            -webkit-tap-highlight-color: transparent;

        }
    }

    .menuButton {
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 4px;
        box-sizing: border-box;
    }

    .languageSelect {
        color: #fff;
        right: 20px;
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 4px;
        position: absolute;
        background-color: transparent;
        outline: none!important;
    }

    .languageSelectOptions {
        background-color: #69b2c0;
    }
}