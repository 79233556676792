.audio-item {
    
    h1 {
        color: #69b2c0;
    }
    
    img {
        max-width: 100%;
        max-height: 100%;
    }
    
    .play-list-back-link {
        
        display: inline-flex;
        text-decoration: none;
        font-weight: 600;
        color: #fff;
        
        .icon-wrapper {
            border: 1px solid #69b2c0;
            margin-bottom: 10px;
            background-color: #69b2c0;
            background: linear-gradient(180deg, rgba(2, 0, 36, 0.8) -50%, rgb(129 208 225) 25%, rgb(83 157 175) 50%, rgb(49 137 155) 75%, rgb(105, 178, 192) 100%);
            border: 1px solid rgba(105,178,192,1);
            border: 0.1px ridge rgb(105, 178, 192, 0.7);
            text-align: center;
        }
        
        .icon-label {
            margin-right: 20px;
        }
        
    }
    
    .icon-item {
        
        padding: 0 10px;
        
        svg {
            color: #fff;
        }
    }
    
    .audio-item-buttons {
        display: flex;
        justify-content: space-between;
    }
    
    .audio-item-actions {
        
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border: 0.1px ridge rgba(105, 178, 192, 0.7);
        
        .prev, .next {
            
            background-color: #69b2c0; 
            text-decoration: none;
            color: #fff;
            background: linear-gradient(180deg, rgba(2, 0, 36, 0.8) -50%, rgb(129 208 225) 25%, rgb(83 157 175) 50%, rgb(49 137 155) 75%, rgb(105, 178, 192) 100%);
            border: 1px solid rgba(105,178,192,1);
            border: 0.1px ridge rgb(105, 178, 192, 0.7);
            width: 100%;
            border: 1px solid rgba(255, 255, 255, 0.5);
            padding: 10px;
            
            .icon-wrapper {
                text-align: center;
            }
            
            .icon-item {
                
                svg {
                    color: #fff;
                }
            }
            
            &.disabled {
                background-color: rgba(124, 124, 124, 0.2);
            }
            
        }
        
        .prev {
            padding-right: 20px;
        }
        
        .next {
            padding-left: 20px;
        }
        
    }
    
}