.row {
    margin-bottom: 20px;
}
.icon {
    vertical-align: middle;
}

.marker-visited {
    border: 1px solid red;
}

.legend {

    display: flex;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;

    .item {

        display: flex;
        align-items: center;

        .label {
            font-weight: 700;
        }

        .image {
            width: 25px;
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }

    .disabled-location {
        color: rgba(255, 0, 0, 1);
    }

}

.map-offline-point-wrapper {

    position: relative;
    display: inline-block;

    .map-offline-image {
        max-width: 100%;
        max-height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .map-offline-row {
            display: flex;
            height: 100%;

            .map-offline-block {
                width: 100%;
                height: 100%;
                position: relative;

                .map-offline-point {
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    .map-offline-point-img {

                        img {
                            height: 35px;
                        }

                    }
                }
            }
        }

        &.debug {

            .map-offline-row {

                .map-offline-block {
                    border: 1px solid red;
                    position: relative;

                    &:hover {
                        background-color: green;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.map-offline-zoom {
    display: flex;
    gap: 20px;
    margin-top: 10px;

    .zoom-button {
        display: inline-block;
        border: 1px solid black;
        padding: 5px 10px;
        box-sizing: border-box;

        &:hover {
            cursor: pointer;
            background-color: black;
            color: #fff;
        }

    }
}

.zoom-buttons {
    display: flex;
    margin-top: 15px;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;
}

.react-transform-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.2);
}