.row {
    margin-bottom: 20px;
}
.icon {
    vertical-align: middle;
}

.audio-list {

    .logo {
        max-width: 100%;
        max-height: 100%;
    }

    .audio-item {

        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .audio-link {
            text-decoration: none;
            width: 100%;

            .audio-package-title {
                background-color: #69b2c0;
                color: #fff;
                padding: 5px;
                background: linear-gradient(180deg, rgba(2, 0, 36, 0.8) -16%, rgb(129 208 225) 25%, rgb(83 157 175) 50%, rgb(49 137 155) 75%, rgb(105, 178, 192) 100%);
            }
            
            .audio-content {
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                
                .audio-title {
                    color: #69b2c0;
                    font-size: 14px;
                }

            }
        }

        .audio-image {
            width: 150px;
            background-size: cover;
            background-repeat: no-repeat;
            height: 100px;
        }
    }

}