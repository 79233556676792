.audio-player-wrapper {
    
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #69b2c0;
    color: #fff;
    padding: 5px 10px;
        background-color: #69b2c0;
        background: linear-gradient(180deg, rgba(2, 0, 36, 0.8) -50%, rgb(129 208 225) 25%, rgb(83 157 175) 50%, rgb(49 137 155) 75%, rgb(105, 178, 192) 100%);
        border: 1px solid rgba(105,178,192,1);
        border: 0.1px ridge rgb(105, 178, 192, 0.7);

    .audio-player {
        
        display: inline-flex;
        align-items: center;
        
        .audio-player-controller {
            display: flex;
        }
    }
    
    audio {
        display: none;
    }
    
}