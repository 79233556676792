body {
  margin: 0;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fafafa;
}

body.overflow-hidden {
    overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
    display: flex;
    min-height: 100vh;
    
    > .content {
        margin-top: 60px;
    }
}

.body header > div {
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.body header > div > button {
    margin-right: 28px;
    margin-left: 0px;
    color: inherit;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    
}

.languageSelect {
    color: #fff;
    right: 20px;
    border: 1px solid #fff;
    padding: 3px;
    position: absolute;
    background-color: transparent;
}

.languageSelectOptions {
    background-color: #69b2c0;
}

main {
    flex-grow: 1;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

main:first-child {
    display: flex;
    padding: 0px 8px;
    min-height: 56px;
    align-items: center;
    justify-content: flex-end;
}

.presentation > div > div {
    width: 240px
}

.drawerHeader {
    display: flex;
    padding: 0px 8px;
    min-height: 56px;
    align-items: center;
    justify-content: flex-end;
}